import React from 'react';
import { Typography } from 'antd';
import Layout from '../components/Layout/layout';
import SEO from '../components/seo';
import Image from '../components/image';
import CtaPrimary from '../components/Buttons/primary-cta';
import CtaSecondary from '../components/Buttons/secondary-cta';
import { viewports } from '../style-vars';

const { Title } = Typography;
const { mdOrSmaller } = viewports;

const NotFoundPage = () => (
  <Layout>
    <SEO title="SpotOn | Page not found" robotsData="noindex, nofollow" />
    <section className="four-o-four">
      <Image imageName="404.png" />
      <Title className="text-center">It seems that you got lost. Sorry.</Title>
      <p className="text-center">The page doesn’t exist or it was deleted.</p>
      <div className="four-o-four__btns">
        <CtaPrimary target="/" ctaTitle="Go Home" />
        <CtaSecondary
          target="/"
          ctaTitle="See how SpotOn can help your business"
        />
      </div>
    </section>

    <style jsx global>{`
      .four-o-four {
        position: relative;
        margin-top: 64px;
      }

      .four-o-four img {
        max-width: 700px;
        transform: translateX(45%);
        object-fit: scale-down !important;
        margin: auto;
      }

      .four-o-four__btns {
        display: grid;
        grid-template-columns: 1fr 1fr;
        padding: 64px 30%;
      }

      @media ${mdOrSmaller} {
        .four-o-four {
          padding: 32px;
        }

        .four-o-four img {
          transform: translateX(0);
        }

        .four-o-four__btns {
          padding: 64px 0;
          grid-template-columns: 1fr;
          grid-gap: 32px;
        }
      }
    `}</style>
  </Layout>
);

export default NotFoundPage;
